import { Data, Override } from "framer"
import { useState, useEffect } from "react"

// Create a data store to keep track of the number input and result
const appState = Data({
    numberEmailInput: 0,
    emailResult: 0,
    numberLoyaltyInput: 0,
    loyaltyResult: 0,
    numberGiftcardInput: 0,
    giftcardResult: 0,
    numberVoucherInput: 0,
    voucherResult: 0,
    totalResult: 0,
})

function updateTotalResult() {
    appState.totalResult =
        appState.emailResult +
        appState.loyaltyResult +
        appState.giftcardResult +
        appState.voucherResult
}

// Override for the number input element
export function NumberInputEmailOverride(): Override {
    return {
        type: "number",
        onChange: (event) => {
            const target = event.target as HTMLInputElement
            const number = parseFloat(target.value)
            console.log("Input Value:", target.value)
            console.log("Parsed Number:", number)

            // Check if the input is a valid number
            if (isNaN(number)) {
                appState.numberEmailInput = 0
                appState.emailResult = 0
            } else {
                appState.numberEmailInput = number

                // Perform basic calculations (e.g., double the input value)
                appState.emailResult = Math.ceil(number / 100)
            }
            updateTotalResult() // Call the function here
        },
        value: appState.numberEmailInput.toString(),
    }
}

// Override for the text element to display the result
export function ResultEmailOverride(): Override {
    const [emailResult, setEmailResult] = useState(appState.emailResult)

    useEffect(() => {
        const interval = setInterval(() => {
            setEmailResult(appState.emailResult)
        }, 100)
        return () => clearInterval(interval)
    }, [])

    console.log("result component", emailResult)

    return {
        text: emailResult.toString(),
    }
}

// Override for the number input element
export function NumberInputLoyaltyOverride(): Override {
    return {
        type: "number",
        onChange: (event) => {
            const target = event.target as HTMLInputElement
            const number = parseFloat(target.value)
            console.log("Input Value:", target.value)
            console.log("Parsed Number:", number)

            // Check if the input is a valid number
            if (isNaN(number)) {
                appState.numberLoyaltyInput = 0
                appState.loyaltyResult = 0
            } else {
                appState.numberLoyaltyInput = number

                // Perform basic calculations (e.g., double the input value)
                appState.loyaltyResult = number
            }
            updateTotalResult() // Call the function here
        },
        value: appState.numberLoyaltyInput.toString(),
    }
}

// Override for the text element to display the result
export function ResultLoyaltyOverride(): Override {
    const [loyaltyResult, setResult] = useState(appState.loyaltyResult)

    useEffect(() => {
        const interval = setInterval(() => {
            setResult(appState.loyaltyResult)
        }, 100)
        return () => clearInterval(interval)
    }, [])

    console.log("result component", loyaltyResult)

    return {
        text: loyaltyResult.toString(),
    }
}

// Override for the number input element
export function NumberInputGiftcardOverride(): Override {
    return {
        type: "number",
        onChange: (event) => {
            const target = event.target as HTMLInputElement
            const number = parseFloat(target.value)
            console.log("Input Value:", target.value)
            console.log("Parsed Number:", number)

            // Check if the input is a valid number
            if (isNaN(number)) {
                appState.numberGiftcardInput = 0
                appState.giftcardResult = 0
            } else {
                appState.numberGiftcardInput = number

                // Perform basic calculations (e.g., double the input value)
                appState.giftcardResult = number * 2
            }
            updateTotalResult() // Call the function here
        },
        value: appState.numberGiftcardInput.toString(),
    }
}

// Override for the text element to display the result
export function ResultGiftcardOverride(): Override {
    const [giftcardResult, setResult] = useState(appState.giftcardResult)

    useEffect(() => {
        const interval = setInterval(() => {
            setResult(appState.giftcardResult)
        }, 100)
        return () => clearInterval(interval)
    }, [])

    console.log("result component", giftcardResult)

    return {
        text: giftcardResult.toString(),
    }
}

// Override for the number input element
export function NumberInputVoucherOverride(): Override {
    return {
        type: "number",
        onChange: (event) => {
            const target = event.target as HTMLInputElement
            const number = parseFloat(target.value)
            console.log("Input Value:", target.value)
            console.log("Parsed Number:", number)

            // Check if the input is a valid number
            if (isNaN(number)) {
                appState.numberVoucherInput = 0
                appState.voucherResult = 0
            } else {
                appState.numberVoucherInput = number

                // Perform basic calculations (e.g., double the input value)
                appState.voucherResult = number * 2
            }
            updateTotalResult() // Call the function here
        },
        value: appState.numberVoucherInput.toString(),
    }
}

// Override for the text element to display the result
export function ResultVoucherOverride(): Override {
    const [voucherResult, setResult] = useState(appState.voucherResult)

    useEffect(() => {
        const interval = setInterval(() => {
            setResult(appState.voucherResult)
        }, 100)
        return () => clearInterval(interval)
    }, [])

    console.log("result component", voucherResult)

    return {
        text: voucherResult.toString(),
    }
}

// Override for the text element to display the total result
export function TotalResultOverride(): Override {
    const [totalResult, setTotalResult] = useState(appState.totalResult)

    useEffect(() => {
        const interval = setInterval(() => {
            setTotalResult(appState.totalResult)
        }, 100)
        return () => clearInterval(interval)
    }, [])

    console.log("total result component", totalResult)

    return {
        text: totalResult.toString(),
    }
}
